import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { Divider, ListItem, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { type ReactNode } from "react";

interface CandidateRequirementListItemProps {
  label: ReactNode;
  url: string;
}

export function CandidateRequirementListItem(props: CandidateRequirementListItemProps) {
  const { label, url } = props;
  return (
    <>
      <ListItem disableGutters>
        <ListItemText
          primary={
            <Text variant="body2" color="ButtonText">
              {label}
            </Text>
          }
        />
        <ListItemSecondaryAction>
          <ExternalLink to={url} sx={{ marginRight: 1.5 }}>
            View
          </ExternalLink>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="fullWidth" component="li" />
    </>
  );
}
