import { Text } from "@clipboard-health/ui-react";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Stack } from "@mui/material";

export function ActivatedPlacementCandidate() {
  return (
    <Stack justifyContent="center" alignItems="center" spacing={2}>
      <VerifiedIcon color="success" fontSize="large" />
      <Text variant="body2" align="center">
        Your profile is activated! Keep an eye out for emails or text messages from local workplaces
        in your area.
      </Text>
    </Stack>
  );
}
